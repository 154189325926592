.menu-tab-element{ 
    height: 40px;
    border-radius: 7px;
}
.menu-tab-element:hover{
    cursor: pointer;
    background-color: #f2f2f2;
}
.rekening-invoice-icon{
    position: relative;
    height: 45px;
    width: 45px;
    /*background-color: #F39F50;*/
    background-color: #f2f2f2;
    border-radius: 10px;
    /*box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.2);*/
    padding: 5px;
    
}
.rekening-invoice-icon.selectable{
    cursor: pointer;
}
.rekening-invoice-icon.selectable:hover{
    background-color: #eed5be;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15); 
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
}

@-o-keyframes fadeIt {
    0%   { background-color: #fff4e1; }
    100% { background-color: #FFFFFF; }
}
@keyframes fadeIt {
    0%   { background-color: #fff4e1; }
    100% { background-color: #FFFFFF; }
}
.changed-invoice{
    background-image: none !important; 
    -o-animation: fadeIt 5s ease-in-out; 
    animation: fadeIt 5s ease-in-out; 
}
.rekening-item-right{
    height: 45px;
    width: 45px;
    padding-left: 5px;
    vertical-align: middle;
    padding-top: 5px;
}
.content-wrapper{
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
}
.menu{
    height: 60px;
    position: fixed;
    top: 0px;
    left: 0px;
    text-align: right;
    float: right;
    right: 0px;
    padding-top: 10px;
    background-color: #fff;
    -webkit-box-shadow: 0px 5px 0px 0px rgba(0,0,0,0.1); 
    box-shadow: 0px 5px 0px 0px rgba(0,0,0,0.1);
    z-index: 10;
}
.DatePicker{
    width: 100%;
}
.invoice-preview{
    cursor: pointer;
    position: relative;
    height: 150px;
    width: 150px;
    text-align: center;
    padding: 20px;
    vertical-align: baseline;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    border: 1px solid #f0f0f0;
    -webkit-box-shadow: 0px 3px 0px 0px rgba(0,0,0,0.1); 
    box-shadow: 0px 3px 0px 0px rgba(0,0,0,0.1);
    transition: all 0.2s;
}
.invoice-preview:hover{
    -webkit-box-shadow: 0px 6px 0px 0px rgba(0,0,0,0.1); 
    box-shadow: 0px 6px 0px 0px rgba(0,0,0,0.1);
}
.invoice-preview p{
    margin-top: 30px;
    font-weight: bold;
}
.icon-add{
    background-color: #F39F50;
    color: black;
    text-align: right;
    float: right;
}
.connector-logo{
    height: 100px;
    width: 40%;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
}
.back-holder{
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 5px;
    width: 40%;
}
.back-holder:hover{
    background-color: #f2f2f2;
}
.back-icon{
    position:absolute;
    margin-top: 8px;
    transition: all 0.2s;
    margin-left: 5px;
}
.back-holder:hover .back-icon{
    margin-left: 0px;
}
.create-first-invoice{
    width: 100%;
    margin-top: 20px;
    display: block;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    background-color: #f2f2f2;
}
.pdf-viewer{
    position: fixed;
    top: 5%;
    left: 1rem;
    right: 80%;
    height: 90%;
    width: calc(100% - 44rem);
    bottom: 5%;
    z-index: 1400;
    overflow: scroll;
}
.pdf-viewer.image{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.list-checkbox{
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 2px solid grey;
    border-radius: 5px;
    /*box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.2);*/
    padding: 2px;
    opacity: 1;
    transition: all 0.2s;
    cursor: pointer;
}
.list-checkbox:hover{
    background-color: #f2f2f2;
}
.rekening-invoice-item{
    position: relative;
}
.rekening-invoice-item:hover .list-checkbox{
    opacity: 1;
}
.rekening-invoice-avatar{
    border-radius: 10px;
    box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.2);
    margin-right: 5px;
    height: 35px;
    width: 35px;
    left: -5px;
    transition: all 0.2s;
}
.rekening-invoice-item:hover .rekening-invoice-avatar,
.rekening-invoice-item.selected .rekening-invoice-avatar{
    transform: perspective(0px) scale3d(0.5, 0.5, 0.5) translateX(25px);
}
.rekening-invoice-item.selected .list-checkbox{
    opacity: 1;
    border-color: #F39F50;
}
.rekening-invoice-item.selected{
    border-color: #F39F50;
}
.rekening-invoice-item.selected .list-checkbox-inner{
    height: 100%;
    width: 100%;
    margin: 0%;
    background-color: #F39F50;
    border-radius: 2px;
}

/* Row Header Select */
.list-checkbox.selected{
    opacity: 1;
    border-color: #F39F50;
}
.list-checkbox.selected .list-checkbox-inner{
    height: 100%;
    width: 100%;
    margin: 0%;
    background-color: #F39F50;
    border-radius: 2px;
}
.rekening-time-picker{
    height: 40px;
    border-radius: 7px;
    width: 100%;
    padding: 10px;
    border: 1px solid #E2E8F0;
}
.subscription-banner{
    position: fixed;
    height: 120px;
    width: 90%;
    left: 5%;
    bottom: 5%;
    border-radius: 10px;
    cursor: pointer;
    background-color: #f39f50;
    text-align: center;
    padding: 15px;
    font-weight: bolder;
    z-index: 99;
    box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.2);
    transition: all 0.2s;
}
.subscription-banner:hover{
    box-shadow: 0px 6px 20px 0px rgba(0,0,0,0.4);
}
.without-highligt{
    box-shadow: none !important;
}
.bottom-actions{
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 55px;
    background-color: white;
    border-top: 1px solid  #E2E8F0;
}

/*TUTORIAL*/
.custom-modal-tutorial-overlay{
    position: fixed;
    background-color: rgba(0,0,0,0.8);
    height: 100%;
    width: 100%;
    z-index: 99999;
    top: 0px;
    left: 0px;
    display: none;
    overflow: scroll;
}
.custom-modal-tutorial-overlay.open{
    display: block;
}
.custom-modal-tutorial{
    position: relative;
    background-color: white;
    height: 70vw;
    width: 80%;
    left: 10%;
    border-radius: 10px;
    top: 80px;
    padding: 20px;
}
.full-iframe{
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: calc(100% - 100px);
}
.custom-modal-tutorial-button{
    position: absolute;
    right: 0px;
}
.custom-modal-tutorial-footer{
    position: relative;
    float: right;
}
.custom-modal-tutorial-bg-overlay{
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
}

.mobile-content{
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: white;
    top: 0px;
    left: 0px;
    z-index: 99;
    display: none;
}
/* When the device width is 768px or less, hide the desktop content and show the mobile content */
@media (max-width: 768px) {
    .desktop-content {
        display: none;
    }
    .mobile-content {
        display: block;
    }
}
.like-chakra-input{
    width:100%;
    min-width:0px;
    outline:2px solid transparent;
    outline-offset:2px;
    position:relative;
    -webkit-appearance:none;
    -moz-appearance:none;
    -ms-appearance:none;
    appearance:none;
    transition-property:var(--chakra-transition-property-common);
    transition-duration:var(--chakra-transition-duration-normal);
    font-size:var(--chakra-fontSizes-md);
    -webkit-padding-start:var(--chakra-space-4);
    padding-inline-start:var(--chakra-space-4);
    -webkit-padding-end:var(--chakra-space-4);
    padding-inline-end:var(--chakra-space-4);
    height:var(--chakra-sizes-10);
    border-radius:var(--chakra-radii-md);
    border:1px solid;
    border-color:inherit;
    background:inherit;
}
.like-chakra-input:focus, .like-chakra-input[data-focus] {
    z-index: 1;
    border-color: #3182ce;
    box-shadow: 0 0 0 1px #3182ce;
}
.like-chakra-input.invalid{
    border-color: #E53E3E;
    box-shadow: 0 0 0 1px #E53E3E;
}
#overlay-modal{
    z-index: 1600 !important;
}
.travelExpenseTotal{
    border-top: #E2E8F0 1px solid;
    padding-top: 5px;
    padding-bottom: 5px;
}
.signup-discount-container{
    position: fixed;
    height: 120px;
    width: 90%;
    left: 5%;
    bottom: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f39f50;
    -webkit-box-shadow: 0px -5px 0px 0px rgba(0,0,0,0.1); 
    box-shadow: 0px -5px 0px 0px rgba(0,0,0,0.1);
    z-index: 10;
}